import "core-js/modules/es.number.constructor.js";
import { defineComponent } from 'vue';
export default defineComponent({
  components: {},
  props: {
    absolut: Number,
    relative: Number,
    normalized: Number,
    formatter: String
  }
});