import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    pvs: {
      type: String,
      "default": null
    },
    wartezeitenSettings: {
      type: Object,
      "default": null
    },
    tq: {
      type: Object,
      "default": null
    }
  },
  computed: {
    hasBehandlungszimmerzeit: function hasBehandlungszimmerzeit() {
      return this.pvs === 'charly';
    },
    daueravgdiff: function daueravgdiff() {
      return this.tq.termindauerdurchschnitt - this.tq.termindauervorgabe;
    },
    dauermediandiff: function dauermediandiff() {
      return this.tq.termindauermedian - this.tq.termindauervorgabe;
    },
    bzavgdiff: function bzavgdiff() {
      return this.tq.behandlungsdauerdurchschnitt - this.tq.termindauervorgabe;
    },
    bzmediandiff: function bzmediandiff() {
      return this.tq.behandlungsdauermedian - this.tq.termindauervorgabe;
    },
    wzwidth: function wzwidth() {
      return this.tq.wartezimmerdauerdurchschnitt / this.wartezeitenSettings.maximum.warteZimmer * 100 + '%';
    },
    bzwidth: function bzwidth() {
      return this.tq.behandlungszimmerdauerdurchschnitt / this.wartezeitenSettings.maximum.behandlungsZimmer * 100 + '%';
    },
    bwidth: function bwidth() {
      return this.tq.behandlungsdauerdurchschnitt / this.tq.termindauerdurchschnitt * 100 + '%';
    },
    tdplusprozente: function tdplusprozente() {
      return {
        good: this.tq.termindauerstatsplus.good / this.tq.termindauerstatsplus.total * 100,
        ok: this.tq.termindauerstatsplus.ok / this.tq.termindauerstatsplus.total * 100,
        bad: this.tq.termindauerstatsplus.bad / this.tq.termindauerstatsplus.total * 100
      };
    },
    tdminusprozente: function tdminusprozente() {
      return {
        good: this.tq.termindauerstatsminus.good / this.tq.termindauerstatsminus.total * 100,
        ok: this.tq.termindauerstatsminus.ok / this.tq.termindauerstatsminus.total * 100,
        bad: this.tq.termindauerstatsminus.bad / this.tq.termindauerstatsminus.total * 100
      };
    },
    wzprozente: function wzprozente() {
      return {
        good: this.tq.wartezimmerstats.good / this.tq.wartezimmerstats.total * 100,
        ok: this.tq.wartezimmerstats.ok / this.tq.wartezimmerstats.total * 100,
        bad: this.tq.wartezimmerstats.bad / this.tq.wartezimmerstats.total * 100
      };
    },
    bzprozente: function bzprozente() {
      return {
        good: this.tq.behandlungszimmerstats.good / this.tq.behandlungszimmerstats.total * 100,
        ok: this.tq.behandlungszimmerstats.ok / this.tq.behandlungszimmerstats.total * 100,
        bad: this.tq.behandlungszimmerstats.bad / this.tq.behandlungszimmerstats.total * 100
      };
    }
  },
  methods: {
    onPopupShow: function onPopupShow() {
      console.log('tq', this.tq);
    }
  }
});