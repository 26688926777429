import { ITeam } from '@rose/types';
import { metricsApi } from './data';
import { authStore } from '@/state/authStore';
import { getViewSpecificBehandlerAccessMembers } from '@/../../base/src';

export const login = async (creds: { email: string; password: string }) => {
  const res = await metricsApi.auth.login(creds);

  if (!validateUserObject(res)) {
    throw new Error('login failed, message: ' + (res as any).message);
  }

  const user = {
    email: res.email,
    cid: res.mainclientid,
    token: res.token,
    firstName: res.firstName,
    lastName: res.lastName,
  };

  return user;
};

function validateUserObject(user: any): boolean {
  return user.cid && user.email && user.token;
}

export function getViewSpecificBehandlerAccess(viewId: string, teams: readonly ITeam[]) {
  const user = authStore.getters.user;

  return getViewSpecificBehandlerAccessMembers({
    viewId,
    viewSpecificBehandlerAccess: user?.viewSpecificBehandlerAccess,
    teams,
  });
}
