import { terminQualitaetStore } from '@/state/terminQualitaetStore';
import { teamsStore } from '@/state/teamsStore';
import { Dictionary, split } from 'lodash';
import { ITeam, PrivatFilterTypeUI } from '@rose/types';

export function setStoreFromParams(routeParams?: Dictionary<any>) {
  if (routeParams) {
    console.log('setStoreFromParams::routeParams', routeParams);
    let newParams = { ...routeParams };
    if (routeParams?.leistungserbringer) {
      terminQualitaetStore.commit.setLeistungserbringerSelected(
        teamsStore.getters.teams.find(t => t.id === routeParams.leistungserbringer),
      );
    }
    if (routeParams?.teams) {
      const teamIds = split(routeParams?.teams);
      const teams: ITeam[] = [];
      for (const tid of teamIds) {
        const team = teamsStore.getters.teams.find(t => t.id === tid);
        if (team) {
          teams.push(team);
        }
      }
      console.log('setStoreFromParams::team', teams);
      terminQualitaetStore.commit.setTeamsSelected(teams);
    }
    if (routeParams?.privat) {
      terminQualitaetStore.commit.setPrivatFilter(routeParams.privat as PrivatFilterTypeUI);
    }
    if (routeParams?.search) {
      terminQualitaetStore.commit.setSearch(routeParams.search as string);
    }
    if (routeParams?.von && routeParams?.bis) {
      terminQualitaetStore.commit.setDate({
        startDate: routeParams.von,
        endDate: routeParams.bis,
      });
    }
    return newParams;
  }
}
